import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import supabase from "~/utils/supabase";
import { useUser } from "./user";
import AddToWishlist from "~/components/add-to-wishlist";
import { MediaAsset } from "types/json";
import CreateWishlist from "~/components/create-wishlist";

export type Wishlist = {
  id: string;
  name: string;
  created_at: string;
  slug: string;
  user_id: string;
};

export type WishlistService = {
  service_id: string;
  created_at: string;
  wishlist_id: string;
  services: {
    name: string;
    images: MediaAsset[];
  };
};

export type SelectedService = {
  id: string;
  image: string;
};

const WishlistContext = createContext<{
  wishlists: Wishlist[];
  setWishlists: Dispatch<SetStateAction<Wishlist[]>>;
  wishlistServices: WishlistService[];
  setWishlistServices: Dispatch<SetStateAction<WishlistService[]>>;
  loading: boolean;
  error: string | null;
  selectedService: string | null;
  setSelectedService: Dispatch<SetStateAction<string | null>>;
  isCreateWishlistOpen: boolean;
  setIsCreateWishlistOpen: Dispatch<SetStateAction<boolean>>;
  removeFromWishlist: (serviceId: string) => Promise<void>;
}>({
  wishlists: [],
  setWishlists: () => {},
  wishlistServices: [],
  setWishlistServices: () => {},
  loading: false,
  error: null,
  selectedService: null,
  setSelectedService: () => {},
  isCreateWishlistOpen: false,
  setIsCreateWishlistOpen: () => {},
  removeFromWishlist: async () => {},
});

export function WishlistProvider({ children }: { children: ReactNode }) {
  const { user } = useUser();
  const [wishlists, setWishlists] = useState<Wishlist[]>([]);
  const [wishlistServices, setWishlistServices] = useState<WishlistService[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedService, setSelectedService] = useState<string | null>(null);

  const [isCreateWishlistOpen, setIsCreateWishlistOpen] = useState(false);

  useEffect(() => {
    const fetchWishlists = async () => {
      try {
        setLoading(true);
        setError(null);

        const { data: wishlistsData, error: wishlistsError } = await supabase
          .from("wishlists")
          .select("name, slug, id, created_at")
          .order("created_at", { ascending: false });

        if (wishlistsError) throw wishlistsError;

        setWishlists(wishlistsData as Wishlist[]);

        const { data: wishlistServicesData, error: wishlistServicesError } =
          await supabase
            .from("wishlist_services")
            .select(
              "service_id, created_at, wishlist_id, services(name, media->images)"
            )
            .order("created_at", { ascending: false });

        if (wishlistServicesError) throw wishlistServicesError;

        setWishlistServices(wishlistServicesData as WishlistService[]);
      } catch (error) {
        setError(error as string);
      } finally {
        setLoading(false);
      }
    };

    if (!user) {
      setLoading(false);
      return;
    }

    fetchWishlists();
  }, [user]);

  const removeFromWishlist = async (serviceId: string) => {
    try {
      const { error } = await supabase
        .from("wishlist_services")
        .delete()
        .eq("service_id", serviceId);

      if (error) throw error;

      setWishlistServices((prev) =>
        prev.filter((ws) => ws.service_id !== serviceId)
      );
    } catch (error) {
      setError(error as string);
    }
  };

  return (
    <WishlistContext.Provider
      value={{
        wishlists,
        setWishlists,
        wishlistServices,
        setWishlistServices,
        loading,
        error,
        selectedService,
        setSelectedService,
        isCreateWishlistOpen,
        setIsCreateWishlistOpen,
        removeFromWishlist,
      }}
    >
      <AddToWishlist
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <CreateWishlist
        isOpen={isCreateWishlistOpen}
        onOpenChange={setIsCreateWishlistOpen}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      {children}
    </WishlistContext.Provider>
  );
}

export const useWishlist = () => useContext(WishlistContext);
