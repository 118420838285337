import {
  Navbar as NextNavbar,
  NavbarBrand,
  NavbarContent,
  cn,
} from "@nextui-org/react";
import UserMenu from "~/components/user-menu";
import { Link } from "react-router";
import LocationPicker from "./location-picker";
import { LuChevronDown } from "react-icons/lu";

type NavbarProps = {
  hideLogo?: boolean;
  hideOnMobile?: boolean;
};

export default function Navbar({
  hideLogo = false,
  hideOnMobile = false,
}: NavbarProps) {
  return (
    <NextNavbar
      isBlurred={false}
      isBordered
      maxWidth="full"
      classNames={{
        wrapper: "px-4 sm:px-6 bg-red-2",
      }}
      className={cn(hideOnMobile && "hidden md:block")}
      position="static"
    >
      <NavbarBrand>
        {!hideLogo && (
          <Link
            viewTransition
            title="tribe"
            className="text-primary font-black text-2xl md:text-3xl"
            to="/"
          >
            tribe<span className="!text-base !md:text-lg">.best</span>
          </Link>
        )}
      </NavbarBrand>

      <NavbarContent justify="end">
        <LocationPicker
          buttonProps={{
            radius: "full",
            variant: "bordered",

            className: "max-w-[140px] px-3",
            endContent: <LuChevronDown className="w-3 h-3 min-w-3 -ml-1" />,
            labelClassName: "text-ellipsis overflow-hidden whitespace-nowrap",
          }}
        />
        <div className="hidden md:block">
          <UserMenu />
        </div>
      </NavbarContent>
    </NextNavbar>
  );
}
