import MediaCarousel from "./media-carousel";
import { formatFollowerCount, pickBestDifferentiator } from "~/utils/misc";
import { Result } from "~/stores/search";
import { prettyLocation } from "~/utils/location";
import posthog from "posthog-js";
import { Link, useSearchParams } from "react-router-dom";
import { Skeleton } from "@nextui-org/skeleton";
import { AiOutlineInstagram } from "react-icons/ai";
import { LuMapPin, LuShoppingBag, LuTrendingUp } from "react-icons/lu";
import Book from "./book";
import { Avatar, Button, Card } from "@nextui-org/react";
import { getPublicAssetURL } from "~/utils/assets";
import BookModal from "./book-modal";
import { useInView } from "react-intersection-observer";
import { HiSparkles } from "react-icons/hi2";
import { useWishlist } from "~/stores/wishlist";
import { GoHeartFill } from "react-icons/go";
import { useUser } from "~/stores/user";

export function ProviderSkeleton() {
  return (
    <div className="w-full h-full">
      <Skeleton className="rounded-2xl w-full aspect-square border border-default-200" />

      <div className="py-3 flex justify-between">
        <div className="flex-1 flex flex-col gap-y-2">
          <Skeleton className="w-56 h-7 rounded-full" />
          <Skeleton className="w-64 h-5 rounded-full" />
          <Skeleton className="w-36 h-5 rounded-full" />
        </div>
        <Skeleton className="w-10 h-5 rounded-full mt-1" />
      </div>
    </div>
  );
}

export default function Provider({
  slug,
  name,
  images,
  q,
  instagram_followers,
  location_label,
  is_online,
  service,
  direction,
  similarity,
  showSimilarity = false,
  image,
  forwardService = true,
}: Result & {
  q: string | null;
  direction: "horizontal" | "vertical";
  showSimilarity?: boolean;
  forwardService?: boolean;
}) {
  const [, setSearchParams] = useSearchParams();

  const { wishlistServices, setSelectedService, removeFromWishlist } =
    useWishlist();

  const { loginCheck } = useUser();

  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: "200px 0px -100px 0px",
  });

  const searchParamsForward = new URLSearchParams();

  if (q) {
    searchParamsForward.set("q", q);
  }

  if (service && forwardService) {
    searchParamsForward.set("service", service.name);
  }

  return (
    <div className="relative w-full">
      <Link
        title={name}
        to={`/p/${slug}?${searchParamsForward.toString()}`}
        viewTransition
        ref={ref}
        className="block flex-shrink-0 w-full h-full relative group shadow-none overflow-visible bg-transparent"
        onClick={() => {
          posthog.capture("result_opened", {
            query: q,
            name: name,
            service: service?.name,
            instagram_followers: instagram_followers,
            images,
            location_label,
          });
        }}
      >
        <div className="relative bg-background">
          {images && images.length > 0 ? (
            <Card>
              <MediaCarousel media={images} direction={direction} />
            </Card>
          ) : (
            <img
              loading="lazy"
              alt="placeholder"
              className="w-full h-full aspect-square object-cover rounded-2xl"
              src="/assets/blur.webp"
            />
          )}
          <div className="absolute left-4 bottom-4 z-10">
            <div
              role="button"
              tabIndex={0}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                posthog.capture("profile_opened", {
                  query: q,
                  name: name,
                });

                setSearchParams(
                  (prev) => {
                    prev.set("about", slug);
                    return prev;
                  },
                  { preventScrollReset: true }
                );
              }}
              className="p-0 !scale-1 active:scale-90 transition-all duration-300"
              onKeyDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (e.key === "Enter" || e.key === " ") {
                  setSearchParams(
                    (prev) => {
                      prev.set("about", slug);
                      return prev;
                    },
                    { preventScrollReset: true }
                  );
                }
              }}
            >
              <Book size={55} isOpen={inView}>
                <Avatar
                  className="border-1 border-default-500"
                  src={image ? getPublicAssetURL(image) : "/assets/blur.webp"}
                />
              </Book>
            </div>
          </div>
        </div>

        {service && (
          <div className="absolute top-2 right-2 z-10">
            <Button
              isIconOnly
              className="bg-transparent rounded-full"
              onPress={() => {
                const isServiceInWishlist = wishlistServices.some(
                  (ws) => ws.service_id === service.id
                );

                if (isServiceInWishlist) {
                  loginCheck(() => removeFromWishlist(service.id));
                } else {
                  loginCheck(() => setSelectedService(service.id));
                }
              }}
            >
              {wishlistServices.some(
                (wishlistService) => wishlistService.service_id === service.id
              ) ? (
                <GoHeartFill className="stroke-white fill-red-500 stroke-2 h-5 w-5 " />
              ) : (
                <GoHeartFill className="stroke-white fill-default-500/70 stroke-2 h-5 w-5" />
              )}
            </Button>
          </div>
        )}

        <div className="py-2 flex flex-col gap-y-1">
          <div className="flex items-start justify-between">
            <h4 className="text-left text-lg font-medium line-clamp-1">
              {name}
            </h4>
            {instagram_followers && instagram_followers > 0 && (
              <div className="flex items-center gap-x-1 mt-1">
                <AiOutlineInstagram className="size-4" />
                <p className="text-sm">
                  {formatFollowerCount(instagram_followers)}
                </p>
              </div>
            )}
          </div>
          {showSimilarity && (
            <div className="flex items-center gap-x-1 text-default-500">
              <LuTrendingUp className="size-4" />
              <span className="block text-sm line-clamp-1">
                {(similarity * 100).toFixed(0)}% match
              </span>
            </div>
          )}
          {service && (
            <div className="flex items-center gap-x-1 line-clamp-1 text-default-500">
              <LuShoppingBag className="size-4" />
              <span className="block text-sm line-clamp-1">{service.name}</span>
              {service.count > 1 && (
                <div className="bg-primary-100 text-default-600 rounded-full px-1 flex items-center justify-center text-xs">
                  +{service.count - 1}
                </div>
              )}
            </div>
          )}
          {service &&
            service.differentiators &&
            service.differentiators.length > 0 && (
              <div className="flex items-center gap-x-1 text-default-500">
                <HiSparkles className="size-4 text-primary-500" />
                <span className="flex-1 text-left text-sm line-clamp-1">
                  {pickBestDifferentiator(service.differentiators)}
                </span>
              </div>
            )}

          {location_label && (
            <div className="flex items-center gap-x-1 text-default-500">
              <LuMapPin className="size-4" />
              <span className="block text-sm line-clamp-1">
                {[is_online ? "Online" : "", prettyLocation(location_label)]
                  .filter(Boolean)
                  .join(" | ")}
              </span>
            </div>
          )}
        </div>
      </Link>
      <BookModal slug={slug} />
    </div>
  );
}
