export function prettyLocation(location: string) {
  if (!location) return "";
  const parts = location.split(",").reverse();
  return parts.slice(2, 4).reverse().join(",");
}

export type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type Place = {
  label: string;
  place_id: string;
};

export type PostalAddress = {
  "@type": "PostalAddress";
  streetAddress: string;
  addressLocality: string;
  postalCode: string;
  addressCountry: string;
};

type BoundingBox = {
  north: number;
  south: number;
  east: number;
  west: number;
};

export function addressComponentsToPostalAddress(
  addressComponents: AddressComponent[]
): PostalAddress {
  let streetNumber = "";
  let route = "";
  let sublocality_level_3 = "";
  let sublocality_level_2 = "";
  let sublocality_level_1 = "";
  let locality = "";
  let postalCode = "";
  let country = "";

  for (const component of addressComponents) {
    if (component.types.includes("street_number")) {
      streetNumber = component.long_name;
    } else if (component.types.includes("route")) {
      route = component.long_name;
    } else if (component.types.includes("sublocality_level_3")) {
      sublocality_level_3 = component.long_name;
    } else if (component.types.includes("sublocality_level_2")) {
      sublocality_level_2 = component.long_name;
    } else if (component.types.includes("sublocality_level_1")) {
      sublocality_level_1 = component.long_name;
    } else if (component.types.includes("locality")) {
      locality = component.long_name;
    } else if (component.types.includes("postal_code")) {
      postalCode = component.long_name;
    } else if (component.types.includes("country")) {
      country = component.short_name;
    }
  }

  return {
    "@type": "PostalAddress",
    streetAddress:
      `${streetNumber} ${route} ${sublocality_level_3} ${sublocality_level_2} ${sublocality_level_1}`.trim(),
    addressLocality: locality,
    postalCode: postalCode,
    addressCountry: country || "IN", // Default to "IN" if country is not found
  };
}

export const getGeolocation = () => {
  return new Promise((resolve, reject) => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            resolve(location);
          },
          (error) => {
            reject(`Error getting geolocation: ${error.message}`);
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    } catch (e) {
      reject("An error occurred while retrieving geolocation.");
    }
  });
};

export const getAutocompletePredictions = (
  input: string,
  location?: { lat: number; lng: number }
) => {
  return new Promise<Place[]>((resolve, reject) => {
    const service = new window.google.maps.places.AutocompleteService();

    const options: google.maps.places.AutocompletionRequest = {
      input,
      // Add location bias if coordinates are provided
      ...(location && {
        location: new window.google.maps.LatLng(location.lat, location.lng),
        radius: 50000, // 50km radius
      }),
    };

    service.getPlacePredictions(options, (predictions, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK ||
        status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
      ) {
        return resolve(
          predictions
            ? predictions.map((p) => ({
                label: p.description,
                place_id: p.place_id,
              }))
            : []
        );
      }
      reject(status);
    });
  });
};
export function validLocationJson(str: string): boolean {
  try {
    const parsed = JSON.parse(str);
    if (
      typeof parsed === "object" &&
      parsed !== null &&
      "lat" in parsed &&
      "lng" in parsed &&
      "label" in parsed
    ) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}

export const geocodeByPlaceId = (placeId: string) => {
  return new Promise<{
    lat: number;
    lng: number;
    address_components: AddressComponent[];
  }>((resolve, reject) => {
    const service = new window.google.maps.Geocoder();

    service.geocode({ placeId: placeId }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        reject(status);
      }

      if (!results) return reject("No results found");

      const location = results[0].geometry.location;
      const address_components = results[0].address_components;

      resolve({
        lat: location.lat(),
        lng: location.lng(),
        address_components,
      });
    });
  });
};

export const geocodeLocation = async (
  latitude: number,
  longitude: number
): Promise<{ label: string; lat: number; lng: number }> => {
  const service = new window.google.maps.Geocoder();
  const latLng = new window.google.maps.LatLng(latitude, longitude);

  return new Promise((resolve, reject) => {
    service.geocode({ location: latLng }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results && results[0]) {
          resolve({
            label: results[0].formatted_address,
            lat: latitude,
            lng: longitude,
          });
        } else {
          reject("No results found");
        }
      } else {
        reject(`Geocoder failed due to: ${status}`);
      }
    });
  });
};

export const isWithinBoundingBox = (
  location: { lat: number; lng: number },
  boundingBox: BoundingBox
) => {
  return (
    location.lat >= boundingBox.south &&
    location.lat <= boundingBox.north &&
    location.lng >= boundingBox.west &&
    location.lng <= boundingBox.east
  );
};

export const NCR_BOUNDING_BOX = {
  north: 29.2433,
  south: 27.8087,
  west: 76.2707,
  east: 77.9337,
  center: {
    lat: 28.5279,
    lng: 77.3015,
    label: "Delhi NCR",
  },
};

export const BANGALORE_BOUNDING_BOX = {
  north: 13.1399,
  south: 12.7342,
  west: 77.3797,
  east: 77.853,
  center: {
    lat: 12.9667,
    lng: 77.5667,
    label: "Bengaluru",
  },
};

export const isWithinServiceableLocation = (location: {
  lat: number;
  lng: number;
}) => {
  return (
    isWithinBoundingBox(location, NCR_BOUNDING_BOX) ||
    isWithinBoundingBox(location, BANGALORE_BOUNDING_BOX)
  );
};
