import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Avatar,
} from "@nextui-org/react";

import { useCallback } from "react";
import {
  LuArrowUpDown,
  LuGift,
  LuHeadphones,
  LuLogIn,
  LuLogOut,
  LuUser,
} from "react-icons/lu";
import { toast } from "react-hot-toast";
import { useUser } from "~/stores/user";
import supabase from "~/utils/supabase";
import { useNavigate } from "react-router";
import { GoCommentDiscussion, GoHeart, GoPerson } from "react-icons/go";
import { maskPhoneNumber } from "~/utils/misc";
import { HiSparkles } from "react-icons/hi2";

export default function UserMenu() {
  const { user, loginCheck, profile } = useUser();
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      const { error } = await supabase.auth.signOut();

      if (error) {
        throw error;
      }

      localStorage.removeItem("aiData");

      toast.success("Logged out successfully");
    } catch (error: unknown) {
      toast.error("Oops! Something went wrong.");
    }
  }, []);

  return (
    <Dropdown backdrop="blur" placement="bottom-end">
      <DropdownTrigger>
        <Avatar
          color={user && user?.id ? "primary" : "default"}
          isBordered
          as="button"
          alt="profile"
          radius="full"
          size="sm"
          name={
            profile?.name && profile.name.length > 0
              ? profile.name[0]
              : undefined
          }
          icon={<LuUser className="w-4 h-4" />}
        />
      </DropdownTrigger>
      {user && user?.id ? (
        <DropdownMenu
          aria-label="Profile Actions"
          variant="flat"
          className="w-64"
        >
          <DropdownSection showDivider>
            <DropdownItem key="profile" isReadOnly textValue="profile">
              <div className="flex gap-3 items-center">
                <Avatar
                  color={user && user?.id ? "primary" : "default"}
                  radius="full"
                  size="sm"
                  name={
                    profile?.name && profile.name.length > 0
                      ? profile.name[0]
                      : undefined
                  }
                  icon={<LuUser className="w-4 h-4" />}
                />
                <div>
                  <span className="block">
                    {profile?.name ? profile.name : ""}
                  </span>
                  <span className="block">
                    {user.phone && `(+${maskPhoneNumber(user.phone)})`}
                  </span>
                </div>
              </div>
            </DropdownItem>
          </DropdownSection>
          <DropdownSection showDivider>
            <DropdownItem
              onPress={() => {
                navigate("/wishlists", { viewTransition: true });
              }}
              key="wishlists"
              color="primary"
              startContent={<GoHeart strokeWidth={1} className="w-4 h-4" />}
            >
              Wishlists
            </DropdownItem>
            <DropdownItem
              onPress={() => {
                navigate("/enquiries", { viewTransition: true });
              }}
              key="enquiries"
              color="primary"
              startContent={
                <GoCommentDiscussion strokeWidth={1} className="w-4 h-4" />
              }
            >
              Enquiries
            </DropdownItem>

            <DropdownItem
              onPress={() => {
                navigate("/", { viewTransition: true });
              }}
              key="enquiries"
              color="primary"
              startContent={<GoPerson strokeWidth={1} className="w-4 h-4" />}
            >
              Personal info
            </DropdownItem>
          </DropdownSection>
          <DropdownSection title="Tribe for Experts" showDivider>
            <DropdownItem
              onPress={() => {
                navigate("/", { viewTransition: true });
              }}
              key="expert-view"
              color="primary"
              startContent={<LuArrowUpDown className="w-4 h-4" />}
              isDisabled
            >
              Switch to expert view
            </DropdownItem>
            <DropdownItem
              onPress={() => {
                navigate("/become-expert", { viewTransition: true });
              }}
              key="expert-view"
              color="primary"
              startContent={<LuGift className="w-4 h-4" />}
            >
              Refer an expert
            </DropdownItem>
          </DropdownSection>
          <DropdownSection title="Support" showDivider>
            <DropdownItem
              onPress={() => {
                navigate("/contact", { viewTransition: true });
              }}
              key="contact"
              color="primary"
              startContent={<LuHeadphones className="w-4 h-4" />}
            >
              Contact founding team
            </DropdownItem>
            <DropdownItem
              onPress={() => {
                navigate("/", { viewTransition: true });
              }}
              key="works"
              color="primary"
              startContent={<HiSparkles className="w-4 h-4" />}
            >
              How tribe.best works?
            </DropdownItem>
          </DropdownSection>
          <DropdownItem
            onPress={logout}
            key="logout"
            color="danger"
            startContent={<LuLogOut className="w-4 h-4" />}
          >
            Log Out
          </DropdownItem>
        </DropdownMenu>
      ) : (
        <DropdownMenu aria-label="Profile Actions" variant="flat">
          <DropdownItem
            onPress={() => {
              loginCheck(() => {});
            }}
            key="login"
            color="primary"
            startContent={<LuLogIn className="w-4 h-4" />}
          >
            Log In
          </DropdownItem>
        </DropdownMenu>
      )}
    </Dropdown>
  );
}
