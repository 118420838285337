import { useEffect } from "react";
import { create } from "zustand";
import { useUser } from "~/stores/user";
import supabase from "~/utils/supabase";
import Provider, { ProviderSkeleton } from "../provider";
import { MediaAsset } from "types/json";

// Define Provider type
type Provider = {
  id: string;
  name: string;
  image: string;
  slug: string;
  instagram_followers: number;
  is_online: boolean;
  score: number;
  location_place_id: string;
  location_label: string;
  location_geography: string;
  dist_meters: number | null;
  service_name: string;
  service_media: {
    images: MediaAsset[];
  };
};

// Create Zustand store
const useProvidersStore = create<{
  providers: Provider[];
  loading: boolean;
  setProviders: (providers: Provider[]) => void;
  setLoading: (loading: boolean) => void;
}>((set) => ({
  providers: [],
  loading: false,
  setProviders: (providers) => set({ providers }),
  setLoading: (loading) => set({ loading }),
}));

// Spotlight Component
export default function Spotlight() {
  const { location } = useUser();
  const { providers, loading, setProviders, setLoading } = useProvidersStore();

  useEffect(() => {
    const init = async () => {
      if (providers.length > 0) return;
      try {
        setLoading(true);
        if (!location?.lat || !location?.lng) return;

        const { data, error } = await supabase.rpc("spotlight", {
          lat: location.lat,
          lng: location.lng,
          limit_val: 15,
          search_radius: 50000,
        });

        if (error) {
          throw error;
        }

        setProviders(data as Provider[]);
      } catch (error) {
        console.error("Error fetching nearby providers:", error);
      } finally {
        setLoading(false);
      }
    };

    init();
  }, [location, providers.length, setLoading, setProviders]);

  if (loading) {
    return (
      <div className="w-full max-w-7xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {Array.from({ length: 8 }).map((_, index) => (
          <ProviderSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (providers.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-8 w-full max-w-7xl">
      <h3 className="px-2 text-xl font-semibold">Spotlight</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {providers.map((provider) => {
          return (
            <Provider
              key={provider.id}
              {...provider}
              image={provider.image}
              images={provider.service_media.images.map((image) => image.id)}
              confidence="high"
              similarity={provider.score}
              service={{
                id: provider.id,
                name: provider.service_name,
                differentiators: [],
                count: 1,
              }}
              provider_id={provider.id}
              q={null}
              direction="horizontal"
              showSimilarity={false}
              forwardService={false}
            />
          );
        })}
      </div>
    </div>
  );
}
