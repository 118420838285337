import posthog from "posthog-js";
import { useMemo } from "react";
import {
  GoCommentDiscussion,
  GoHeart,
  GoPerson,
  GoSearch,
} from "react-icons/go";
import { useNavigate, useLocation } from "react-router";
import { useScrollDirection } from "~/hooks/use-scroll-direction";
import { useUser } from "~/stores/user";

const allowedPaths = [
  /^\/$/, // Home path
  /^\/wishlists$/, // Wishlists path
  /^\/search$/, // Search path
  /^\/enquiries$/, // Enquiries path
  /^\/profile$/, // Profile path
  /^\/c\/.+/, // Collection paths
];

export default function BottomNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loginCheck } = useUser();
  const { scrollDirection } = useScrollDirection(10);

  const isVisible = scrollDirection === "up" || scrollDirection === null;

  const routes = useMemo(() => {
    return [
      {
        label: "Explore",
        icon: GoSearch,
        href: "/",
        alternative: "/search",
        onClick: () => {
          navigate("/", { viewTransition: true });
        },
      },
      {
        label: "Wishlists",
        icon: GoHeart,
        href: "/wishlists",
        onClick: () => {
          navigate("/wishlists", { viewTransition: true });
        },
      },
      {
        label: "Enquiries",
        icon: GoCommentDiscussion,
        href: "/enquiries",
        onClick: () => {
          navigate("/enquiries", { viewTransition: true });
        },
      },
      {
        label: user ? "Profile" : "Log In",
        icon: GoPerson,
        href: "/profile",
        onClick: () => {
          loginCheck(() => navigate("/profile", { viewTransition: true }));
        },
      },
    ];
  }, [loginCheck, navigate, user]);

  if (!allowedPaths.some((pattern) => pattern.test(location.pathname))) {
    return null;
  }

  return (
    <div
      className={`shadow-xl
      bg-background border-t fixed -bottom-0.5 left-0 right-0 z-50
      flex items-center transform transition-transform duration-300
      md:hidden
      ${isVisible ? "translate-y-0" : "translate-y-full"}
    `}
    >
      {routes.map((route) => {
        const isActive =
          location.pathname === route.href ||
          location.pathname === route.alternative;

        return (
          <button
            onClick={() => {
              posthog.capture("bottom_navbar_page_changed", {
                label: route.label,
                href: route.href,
              });
              route.onClick();
            }}
            key={route.href}
            className="flex-1 py-3 px-2 flex flex-col justify-center items-center"
          >
            <route.icon
              className={`size-6 mb-1 ${
                isActive ? "text-primary stroke-[0.9]" : ""
              }`}
            />
            <span className={`block text-xs ${isActive ? "text-primary" : ""}`}>
              {route.label}
            </span>
          </button>
        );
      })}
    </div>
  );
}
