import { cn } from "@nextui-org/react";
import { HiSparkles } from "react-icons/hi2";

import Search from "~/components/search";
import Suggestions from "~/components/suggestions";
import HowItWorks from "~/components/how-it-works";
import Navbar from "~/components/navbar";
import { MetaFunction } from "react-router";
import { OG_METADATA } from "~/utils/constants";
import Spotlight from "~/components/spotlight";
import { useScrollDirection } from "~/hooks/use-scroll-direction";
import WhyTribe from "~/components/why-tribe";

export const meta: MetaFunction = () => {
  return [
    { charset: "utf-8" },
    { title: "Tribe - Find service experts who get your vibe ✨" },
    {
      name: "description",
      content:
        "Discover India's top service experts on Tribe! From photographers to interior designers and fitness trainers, find the perfect match for your needs today.",
    },
    ...OG_METADATA,
  ];
};

export default function Index() {
  const { lastScrollY } = useScrollDirection(10);

  return (
    <>
      <Navbar hideLogo={true} />
      <section className="flex flex-col items-center relative">
        <div className="max-w-xl w-full px-4 text-center z-10 mt-12 mb-4">
          <h1 className="text-5xl md:text-8xl font-black text-primary mb-2  animate-fade-down animate-duration-800">
            tribe<span className="text-xl md:text-4xl">.best</span>
          </h1>
          <p className="text-lg md:text-xl font-semibold drop-shadow-md leading-9">
            Find service experts who get your vibe
            <HiSparkles className="inline-block ml-1 mb-1 text-primary" />
          </p>
        </div>
      </section>

      <div
        className={cn(
          "w-full sticky top-0 z-20 px-4 py-4 sm:px-6 flex justify-center bg-background",
          lastScrollY > 200 && "border-b shadow-lg"
        )}
      >
        <div className="max-w-xl w-full">
          <Search placeholder="Describe any service you need..." />
        </div>
      </div>

      <div className="mt-12">
        <Suggestions />
      </div>
      <div className="flex flex-col gap-4 items-center mt-24 w-full border-t border-default-200 px-6 pb-8">
        <HowItWorks />
        <WhyTribe />
      </div>
      <div className="flex flex-col md:items-center px-6 py-8 w-full border-t border-default-200">
        <Spotlight />
      </div>
    </>
  );
}
