import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  ModalHeader,
} from "@nextui-org/react";
import { useEffect, useCallback } from "react";
import { MdOutlineWrongLocation } from "react-icons/md";
import { useUser } from "~/stores/user";
import {
  BANGALORE_BOUNDING_BOX,
  isWithinBoundingBox,
  NCR_BOUNDING_BOX,
} from "~/utils/location";
import posthog from "posthog-js";
import { BANGALORE } from "~/utils/constants";

export default function LocationWarningModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { location, setLocation } = useUser();

  const handleClose = useCallback(
    (switchToBengaluru: boolean = false) => {
      posthog.capture("location_warning_modal_closed");

      if (switchToBengaluru) {
        setLocation({
          lat: BANGALORE.latitude,
          lng: BANGALORE.longitude,
          label: BANGALORE.label,
        });
      }

      onClose();
    },
    [onClose, setLocation]
  );

  useEffect(() => {
    if (location) {
      const isNCR = isWithinBoundingBox(location, NCR_BOUNDING_BOX);
      const isBangalore = isWithinBoundingBox(location, BANGALORE_BOUNDING_BOX);
      if (!isNCR && !isBangalore) {
        onOpen();
      }
    }
  }, [location, onOpen]);

  useEffect(() => {
    if (isOpen) {
      posthog.capture("location_warning_modal_opened");
    }
  }, [isOpen]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpen} hideCloseButton>
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalBody className="flex flex-col items-center gap-6">
            <MdOutlineWrongLocation className="w-16 h-16 text-danger" />
            <h3 className="text-lg font-semibold">
              Oops, we&apos;re not here yet!
            </h3>
            <p className="text-sm text-center">
              Our amazing experts are in{" "}
              <span className="font-bold">Bengaluru</span> and{" "}
              <span className="font-bold">Delhi NCR</span> right now. Your city
              is next - but give us a spin!
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className="flex-1"
              color="primary"
              variant="bordered"
              onPress={() => handleClose(false)}
            >
              Continue with selection
            </Button>
            <Button
              className="flex-1"
              color="primary"
              onPress={() => handleClose(true)}
            >
              Switch to Bengaluru
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
