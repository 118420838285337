import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useWishlist, WishlistService } from "~/stores/wishlist";
import supabase from "~/utils/supabase";
import WishlistCard from "./wishlist-card";

export default function AddToWishlist({
  selectedService,
  setSelectedService,
}: {
  selectedService: string | null;
  setSelectedService: (service: string | null) => void;
}) {
  const { wishlists, setIsCreateWishlistOpen, setWishlistServices } =
    useWishlist();

  const onWishlistCardPress = useCallback(
    async (wishlistId: string) => {
      if (!selectedService) return;

      try {
        const { data, error } = await supabase
          .from("wishlist_services")
          .insert({
            wishlist_id: wishlistId,
            service_id: selectedService,
          })
          .select(
            "wishlist_id, created_at, service_id, services(name, media->images)"
          )
          .single();

        if (error) {
          throw error;
        }

        setWishlistServices((prev: WishlistService[]) => [
          data as WishlistService,
          ...prev,
        ]);

        setSelectedService(null);
      } catch (error) {
        toast.error("Failed to add to wishlist");
      }
    },
    [selectedService, setSelectedService, setWishlistServices]
  );

  return (
    <>
      <Modal
        size="2xl"
        isOpen={selectedService !== null}
        onOpenChange={() => setSelectedService(null)}
        backdrop="blur"
        scrollBehavior="inside"
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">
            Add to wishlist
          </ModalHeader>
          <ModalBody className="grid grid-cols-2 sm:grid-cols-3 gap-6 px-6">
            {wishlists.map((wishlist) => {
              return (
                <WishlistCard
                  key={wishlist.id}
                  wishlist={wishlist}
                  onPress={() => onWishlistCardPress(wishlist.id)}
                />
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button
              size="lg"
              onPress={() => {
                setIsCreateWishlistOpen(true);
              }}
              color="primary"
              fullWidth
            >
              Create new wishlist
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
