import { ModalBody, ModalFooter, ModalHeader } from "@nextui-org/react";
import { useEffect, useRef } from "react";
import { LuCheckCircle } from "react-icons/lu";

export default function LoginSuccess({ onSuccess }: { onSuccess: () => void }) {
  const called = useRef(false);

  useEffect(() => {
    if (called.current) return;
    called.current = true;

    setTimeout(() => {
      onSuccess();
    }, 2000);
  }, [onSuccess]);

  return (
    <>
      <ModalHeader></ModalHeader>
      <ModalBody>
        <div className="flex items-center justify-center mt-6">
          <LuCheckCircle className="h-12 w-12 text-success-500" />
        </div>
        <h2 className="text-center text-2xl font-semibold">All Set!</h2>
      </ModalBody>
      <ModalFooter className="flex justify-center">
        <p className="text-sm text-default-500 mb-4">
          This dialog will close automatically.
        </p>
      </ModalFooter>
    </>
  );
}
