import { Modal, ModalContent } from "@nextui-org/react";
import { ReactNode, useState } from "react";
import { Link } from "@nextui-org/link";
import MobileForm from "./mobile-form";
import OtpForm from "./otp-form";
import OnboardingForm from "./onboarding-form";
import LoginSuccess from "./login-success";
import posthog from "posthog-js";

type LoginFlowProps = {
  isOpen: boolean;
  onOpenChange: () => void;
  onSuccess: () => void;
  header?: ReactNode;
};

export default function LoginDialog({
  isOpen,
  onOpenChange,
  onSuccess,
  header,
}: LoginFlowProps) {
  const [step, setStep] = useState<"mobile" | "otp" | "onboarding" | "success">(
    "mobile"
  );
  const [phone, setPhone] = useState<string>("");

  const closeModal = () => {
    if (step === "mobile" || step === "otp") {
      posthog.capture("login_cancelled", { step });
    }

    setStep("mobile");
    setPhone("");
    onOpenChange();
  };

  return (
    <Modal
      isOpen={isOpen}
      backdrop="blur"
      onOpenChange={closeModal}
      placement="bottom-center"
      isDismissable={step == "onboarding" ? false : true}
      hideCloseButton={true}
    >
      <ModalContent>
        {header && <div className="p-6 border-b">{header}</div>}
        {step === "mobile" && (
          <MobileForm
            onSuccess={(phone: string) => {
              setStep("otp");
              setPhone(phone);
            }}
          />
        )}
        {step === "otp" && (
          <OtpForm
            phone={phone}
            setStep={setStep}
            onSuccess={(userProfileExists: boolean) => {
              if (userProfileExists) {
                setStep("success");
              } else {
                setStep("onboarding");
              }
            }}
          />
        )}
        {step === "onboarding" && (
          <OnboardingForm
            onSuccess={() => {
              posthog.capture("onboarding_completed");
              setStep("success");
            }}
          />
        )}
        {step === "success" && (
          <LoginSuccess
            onSuccess={() => {
              closeModal();
              onSuccess();
            }}
          />
        )}
        <p className="text-center text-sm p-4 border-t">
          By continuing, you agree to our{" "}
          <Link target="_blank" href="/terms" size="sm">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link target="_blank" href="/privacy" size="sm">
            Privacy Policy
          </Link>
        </p>
      </ModalContent>
    </Modal>
  );
}
