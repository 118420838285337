import { Button, cn } from "@nextui-org/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LuArrowRight, LuSearch } from "react-icons/lu";
import { Input } from "@nextui-org/input";
import posthog from "posthog-js";
import { useNavigate, useSearchParams } from "react-router";
import ReactGA from "react-ga4";

export default function Search({ placeholder }: { placeholder?: string }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [query, setQuery] = useState("");

  const q = useMemo(
    () => searchParams.get("q")?.toString() || "",
    [searchParams]
  );

  useEffect(() => {
    setQuery(q);
  }, [q]);

  const search = useCallback(
    (q: string) => {
      const trackMeta = async () => {
        await import("react-facebook-pixel")
          .then((x) => x.default)
          .then((ReactPixel) => {
            ReactPixel.track("Search", {
              search_string: q,
              currency: "INR",
              value: 100,
            });
          });
      };

      // Custom Search
      const trackGTM = async () => {
        ReactGA.event("conversion", {
          send_to: "AW-11437691875/_B30CLC5jPkZEOOn9c0q",
          value: 10,
          currency: "INR",
        });
      };

      trackMeta();
      trackGTM();

      posthog.capture("custom_search", {
        query: q,
      });

      const searchParams = new URLSearchParams();
      searchParams.set("q", q);

      navigate(`/search?${searchParams.toString()}`);
    },
    [navigate]
  );

  return (
    <Input
      className="group focus:placeholder:opacity-0 drop-shadow-md"
      aria-label="Search"
      radius="full"
      size="lg"
      color="primary"
      variant="faded"
      placeholder={placeholder}
      value={query}
      onValueChange={setQuery}
      isClearable={false}
      onKeyDown={(e) => {
        if (e.key === "Enter" && query !== "") {
          search(query);
        }
      }}
      startContent={
        <LuSearch
          className={cn(
            "w-5 h-5 -ml-2 text-primary",
            query.length === 0 && "text-default-400"
          )}
        />
      }
      endContent={
        <Button
          isIconOnly
          className={cn(
            "opacity-0 transition-all rounded-full -mr-3",
            query.length > 0 && "group-focus-within:opacity-100"
          )}
          color="primary"
          size="sm"
          onPress={() => search(query)}
          isDisabled={query === ""}
        >
          <LuArrowRight className="w-4 h-4" />
        </Button>
      }
    />
  );
}
