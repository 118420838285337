export function cosineSimilarity(vectorA: number[], vectorB: number[]): number {
  // Ensure the vectors have the same length
  if (vectorA.length !== vectorB.length) {
    throw new Error("Vectors must have the same length");
  }

  // Calculate the dot product of the two vectors
  const dotProduct = vectorA.reduce(
    (sum, val, idx) => sum + val * vectorB[idx],
    0
  );

  // Calculate the magnitude of each vector
  const magnitudeA = Math.sqrt(
    vectorA.reduce((sum, val) => sum + val * val, 0)
  );
  const magnitudeB = Math.sqrt(
    vectorB.reduce((sum, val) => sum + val * val, 0)
  );

  // Calculate the cosine similarity
  if (magnitudeA === 0 || magnitudeB === 0) {
    throw new Error("Vectors must not be zero vectors");
  }

  return dotProduct / (magnitudeA * magnitudeB);
}

export function formatFollowerCount(count: number): string {
  if (count < 1000) {
    return count.toString();
  }

  if (count < 1000000) {
    return `${(count / 1000).toFixed(1)}k`;
  }

  return `${(count / 1000000).toFixed(1)}m`;
}

export function slugify(text: string) {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

export function deslugify(slug: string) {
  return slug
    .replace(/-/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase())
    .replaceAll("And", "&");
}

export function pickBestDifferentiator(differentiators: string[]) {
  // Give first priority to the ones that include ":" and then the ones that include multiple commas

  const colonDifferentiators = differentiators.filter((d) => d.includes(":"));
  const commaDifferentiators = differentiators.filter((d) => d.includes(","));

  if (colonDifferentiators.length > 0) {
    return colonDifferentiators[0];
  }

  if (commaDifferentiators.length > 0) {
    return commaDifferentiators[0];
  }

  return differentiators[0];
}

export function maskPhoneNumber(phone: string) {
  // Replace all characters except last 4 with "x"
  return phone.slice(0, -4).replace(/\d/g, "X") + phone.slice(-4);
}
