import { Card, CardBody } from "@nextui-org/react";

const features = [
  {
    image: "/assets/illustrations/find-expert.svg",
    action: "Find",
    description: "experts you vibe with, in 2 mins",
  },
  {
    image: "/assets/illustrations/enquire-prices.svg",
    action: "Enquire",
    description: "prices & availability in a click",
  },
  {
    image: "/assets/illustrations/hire-confidently.svg",
    action: "Hire",
    description: "confidently with vetted reviews & portfolios",
  },
];

export default function WhyTribe() {
  return (
    <div className="w-full max-w-7xl flex flex-col gap-8">
      <h3 className="text-xl text-center font-semibold">
        Skip the Instagram hustle
        <br />
        Finding on <span className="text-primary">tribe.best</span> is super
        easy
      </h3>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
        {features.map((feature, index) => (
          <Card key={index} className="h-32">
            <CardBody className="flex flex-row items-center justify-between pr-0">
              <div className="flex flex-row items-center gap-2">
                <div className="space-y-4">
                  <div className="flex items-center justify-center rounded-full bg-primary-100 size-6">
                    <span className="text-primary font-medium tex-sm">
                      {index + 1}
                    </span>
                  </div>
                  <p>
                    <span className="text-primary font-medium">
                      {feature.action}
                    </span>{" "}
                    {feature.description}
                  </p>
                </div>
              </div>
              <img className="h-24" src={feature.image} alt={feature.action} />
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
}
