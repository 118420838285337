import { cn } from "@nextui-org/react";
import { useNavigate } from "react-router";
import posthog from "posthog-js";
import { LuSearch } from "react-icons/lu";
import { useCallback } from "react";

export type Suggestion = {
  type: "category" | "query";
  value: string;
  image?: string;
  color?: string;
};

export default function SuggestionCard({
  suggestion,
}: {
  suggestion: Suggestion;
}) {
  const navigate = useNavigate();

  const handleCategoryClick = useCallback(async () => {
    const trackMeta = async () => {
      await import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.track("Search", {
            search_string: suggestion.value,
            currency: "INR",
            value: 10,
          });
        });

      const searchParams = new URLSearchParams();
      searchParams.set("q", suggestion.value);

      navigate(`/search?${searchParams.toString()}`, { viewTransition: true });
    };

    await trackMeta();

    posthog.capture("sample_search", {
      type: "category",
      query: suggestion.value,
    });
  }, [navigate, suggestion.value]);

  const handleLongTailClick = useCallback(() => {
    posthog.capture("sample_search", {
      type: "longtail",
      query: suggestion.value,
    });

    const searchParams = new URLSearchParams();
    searchParams.set("q", suggestion.value);

    navigate(`/search?${searchParams.toString()}`, { viewTransition: true });
  }, [navigate, suggestion.value]);

  if (suggestion.type === "category") {
    return (
      <div
        role="button"
        onClick={handleCategoryClick}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handleCategoryClick();
          }
        }}
      >
        <div className="transform-gpu">
          <div
            className={cn(
              "w-[200px] h-[80px] rounded-2xl relative overflow-hidden group"
            )}
          >
            <img
              loading="lazy"
              className="w-full h-full object-cover rounded-md"
              alt={suggestion.value}
              src={suggestion.image}
            />
            <div className="absolute inset-0 flex justify-center items-center gap-2 p-2 md:p-4 bg-black bg-opacity-40 group-hover:bg-opacity-50 transition-all duration-300">
              {/* <LuSearch className="text-white shrink-0" /> */}
              <p className="text-center font-bold text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]">
                {suggestion.value}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      role="button"
      onClick={handleLongTailClick}
      className="w-max"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleLongTailClick();
        }
      }}
    >
      <div className="transform-gpu">
        <div
          className={cn(
            "w-[200px] h-[80px] p-2 md:p-4 rounded-2xl flex items-center gap-2",
            `bg-gradient-to-br from-${suggestion.color}-500 to-${suggestion.color}-800 text-${suggestion.color}-100`
          )}
        >
          <LuSearch className={cn(`text-${suggestion.color}-100 shrink-0`)} />

          <p className="flex-1 text-sm font-medium">
            &quot;{suggestion.value}&quot;
          </p>
        </div>
      </div>
    </div>
  );
}
