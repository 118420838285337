import { useEffect, useRef, useState } from "react";
import { useNavigation } from "react-router";

export default function NavigationProgress() {
  const navigation = useNavigation();
  const [progress, setProgress] = useState(0);
  const progressRef = useRef<number | null>(null);

  useEffect(() => {
    if (navigation.state !== "idle") {
      if (progressRef.current) {
        clearInterval(progressRef.current);
      }
      setProgress(0);
      progressRef.current = window.setInterval(() => {
        setProgress((prev) => Math.min(prev + 10, 90));
      }, 300);
    } else {
      if (progressRef.current) {
        clearInterval(progressRef.current);
      }
      setProgress(100);
      setTimeout(() => setProgress(0), 300);
    }
  }, [navigation.state]);

  if (progress === 0) {
    return null;
  }

  return (
    <div
      className="fixed w-screen h-0.5 top-0 right-0 left-0 z-50 bg-primary-500 transition-all duration-300"
      style={{ width: `${progress}%` }}
    />
  );
}
