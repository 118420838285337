import { Card, Skeleton } from "@nextui-org/react";
import { useMemo } from "react";
import { Wishlist, useWishlist } from "~/stores/wishlist";
import { getPublicAssetURL } from "~/utils/assets";

export function WishlistCardSkeleton() {
  return (
    <div>
      <Card>
        <div className="grid grid-cols-2 gap-1 p-2">
          {[...Array(4)].map((_, index) => (
            <Skeleton
              key={index}
              className="w-full h-full aspect-square rounded-md bg-default-100 animate-pulse"
            />
          ))}
        </div>
      </Card>
      <div className="py-2 flex flex-col">
        <Skeleton className="h-5 w-32 bg-default-100 rounded-full animate-pulse mt-1 mb-2" />
        <Skeleton className="h-4 w-16 bg-default-100 rounded-full animate-pulse" />
      </div>
    </div>
  );
}

export default function WishlistCard({
  wishlist,
  onPress,
}: {
  wishlist: Wishlist;
  onPress: () => void;
}) {
  const { wishlistServices } = useWishlist();

  const services = useMemo(() => {
    return wishlistServices.filter((ws) => ws.wishlist_id === wishlist.id);
  }, [wishlistServices, wishlist.id]);

  return (
    <Card
      key={wishlist.id}
      isPressable
      onPress={onPress}
      shadow="none"
      radius="none"
      className="h-max p-0 overflow-visible"
    >
      <Card>
        <div className="grid grid-cols-2 gap-2 p-2">
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="col-span-1">
              <img
                src={
                  services[index]?.services?.images
                    ? getPublicAssetURL(services[index].services.images[0].id)
                    : "/assets/blur.webp"
                }
                alt={services[index]?.services?.name}
                className="w-full h-full aspect-square rounded-md object-cover"
              />
            </div>
          ))}
        </div>
      </Card>
      <div className="py-2 flex flex-col">
        <h4 className="text-left text-lg font-medium line-clamp-1">
          {wishlist.name}
        </h4>
        <p className="text-sm text-default-500 text-left -mt-0.5">
          {
            wishlistServices.filter((ws) => ws.wishlist_id === wishlist.id)
              .length
          }{" "}
          saved
        </p>
      </div>
    </Card>
  );
}
