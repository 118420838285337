import { useState, useEffect } from "react";

export type ScrollDirection = "up" | "down" | null;

export function useScrollDirection(threshold = 0) {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(null);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const diff = currentScrollY - lastScrollY;

      // Only update direction if scroll difference exceeds threshold
      if (Math.abs(diff) > threshold) {
        const direction = diff > 0 ? "down" : "up";
        setScrollDirection(direction);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, threshold]);

  return { scrollDirection, lastScrollY };
}
