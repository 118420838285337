import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "@nextui-org/react";
import { useCallback, useState } from "react";
import { useUser } from "~/stores/user";
import { useWishlist, Wishlist, WishlistService } from "~/stores/wishlist";
import supabase from "~/utils/supabase";
import { v4 as uuid } from "uuid";
import { slugify } from "~/utils/misc";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router";

interface CreateWishlistProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  selectedService: string | null;
  setSelectedService: (service: string | null) => void;
}

export default function CreateWishlist({
  isOpen,
  onOpenChange,
  selectedService,
  setSelectedService,
}: CreateWishlistProps) {
  const [searchParams] = useSearchParams();
  const { user } = useUser();
  const { setWishlists, setWishlistServices } = useWishlist();

  const q = searchParams.get("q");

  const [wishlistName, setWishlistName] = useState<string>(q || "");
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreatePress = useCallback(async () => {
    if (!user?.id) return;

    try {
      setLoading(true);
      const id = uuid();

      const { data: wishlist, error: wishlistCreateError } = await supabase
        .from("wishlists")
        .insert({
          id,
          name: wishlistName,
          slug: slugify(wishlistName).concat("-", id.slice(0, 5)),
          user_id: user?.id,
          created_at: new Date().toISOString(),
        })
        .select()
        .single();

      setWishlists((prev: Wishlist[]) => [wishlist as Wishlist, ...prev]);

      if (wishlistCreateError) throw wishlistCreateError;

      if (wishlist && selectedService) {
        const { data: wishlistService, error: wishlistServiceCreateError } =
          await supabase
            .from("wishlist_services")
            .insert({
              wishlist_id: wishlist.id,
              service_id: selectedService,
              created_at: new Date().toISOString(),
            })
            .select(
              "wishlist_id, created_at, service_id, services(name, media->images)"
            )
            .single();

        if (wishlistServiceCreateError) throw wishlistServiceCreateError;

        setWishlistName("");

        setWishlistServices((prev: WishlistService[]) => [
          wishlistService as WishlistService,
          ...prev,
        ]);

        setSelectedService(null);
        onOpenChange(false);
      }
    } catch (error) {
      toast.error("Failed to create wishlist");
    } finally {
      setLoading(false);
    }
  }, [
    user?.id,
    wishlistName,
    setWishlists,
    selectedService,
    setWishlistServices,
    setSelectedService,
    onOpenChange,
  ]);

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} backdrop="blur">
        <ModalContent>
          <>
            <ModalHeader className="flex flex-col gap-1">
              Create wishlist
            </ModalHeader>
            <ModalBody>
              <Input
                autoFocus
                placeholder="Wishlist name"
                value={wishlistName}
                maxLength={50}
                isClearable
                onValueChange={(value: string) => setWishlistName(value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                size="lg"
                color="primary"
                isLoading={loading}
                onPress={() => {
                  handleCreatePress();
                }}
              >
                Create
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  );
}
