import { useCallback, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import supabase from "~/utils/supabase";

interface FormValues {
  phone: string;
}

export default function MobileForm({
  onSuccess,
}: {
  onSuccess: (phone: string) => void;
}) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { phone: "" },
  });

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        setLoading(true);

        const { error } = await supabase.auth.signInWithOtp({
          phone: `91${values.phone}`,
        });

        if (error) {
          throw error;
        }

        onSuccess(values.phone);
      } catch (e) {
        setErrorMessage("Unexpected error while sending OTP.");
      } finally {
        setLoading(false);
      }
    },
    [onSuccess]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader className="flex flex-col">
        <h2 className="text-xl font-bold">Login</h2>
        <p className="text-sm font-normal text-default-500 mb-4">
          Login to your account to continue
        </p>
      </ModalHeader>
      <ModalBody>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: "Phone number is required",
            pattern: {
              value: /^\d{10}$/,
              message: "Please provide a valid 10-digit phone number",
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              type="tel"
              size="lg"
              label="Phone"
              autoFocus
              startContent={<span>+91</span>}
              variant="bordered"
              isInvalid={fieldState.invalid}
              value={field.value}
              errorMessage={fieldState.error?.message}
              onValueChange={field.onChange}
            />
          )}
        />
        {errorMessage && (
          <p className="text-sm text-center text-danger">{errorMessage}</p>
        )}
      </ModalBody>
      <ModalFooter className="flex flex-col gap-8">
        <Button
          type="submit"
          color="primary"
          className="w-full"
          isLoading={loading}
          size="lg"
        >
          Continue
        </Button>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-default-500">
            🚫 We have a strict no spam policy
          </p>
          <p className="text-sm text-default-500">
            🔒 We never share your phone number
          </p>
        </div>
      </ModalFooter>
    </form>
  );
}
